export const containsAtLeastOneUppercaseLetterValidator = () => {
  return (value: string) => ({ valid: value && value.match(/[A-Z]+/), name: 'containsAtLeastOneUppercaseLetter' });
};

export const containsAtLeastOneNumberValidator = () => {
  return (value: string) => ({ valid: value && value.match(/[0-9]+/), name: 'containsAtLeastOneNumber' });
};

export function arrayRequired() {
  return (val) => {
      let valid = true;
      if (val === undefined || val === null)
          valid = false;
      if (Array.isArray(val)) {
          valid = val.length >= 1;
      }
      return { valid, name: 'arrayRequired' };
  };
}